import React from "react"
import './imagelayer.css'


const Office = ({ city, address, phone, fax, email, image }) => {
  return (
    <div className="col-lg-4 col-sm-12  col-md-6 col-xs-8 box" style={{
      backgroundImage: `url(${image})`}}>
      <div className="col-lg-10 col-sm-11 col-xs-12 col-md-11 CenterDiv layer">
        <p className="headingStyle">{city}</p>
        <div className="  outer-box"><p className="ImageTextStyle">{address}</p></div>
        <div className="tel-box  "><p>Tel: {phone};
        < br />Fax: {fax}</p></div>
        <p>{email}</p>
    </div>
    </div>
  )
}
export default Office
