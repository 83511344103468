import React from "react"
import styled from "styled-components"
import Content from "../components/Content"
import Layout from "../components/Layout"
import Heading from '../components/elements/Heading'
import OfficeListing from "../components/Offices/OfficeListing"
import { graphql, useStaticQuery } from "gatsby"
import './ourOffices.css'

const Offices = () => {
  const data = useStaticQuery(graphql`
  query {
    contentfulBannerImages(path: {eq: "/OurOffices"}) {
      image {
        fluid(quality:100) {
          src
        }
      }
      heading
    }
    ourOfficesBlock: contentfulPageTextAndHeadings(path: {eq: "/OurOffices"}) {
      mainHeading1
    }
  }
  `)

  const ImgDiv = styled.div`
  background-image: url(${data.contentfulBannerImages.image.fluid.src});
  height: 300px;
  color: #ffffff;
  object-fit: cover;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`
  return (
    <Layout>


      <ImgDiv>
        <h3
          style={{
            fontFamily: "Playfair Display",
            fontSize: 50,
            fontWeight: "bold",
            textAlign: "center",
            margin: "auto",
            paddingTop: 100,
          }}
        >
          {data.contentfulBannerImages.heading}
        </h3>
      </ImgDiv>
      <div className="container my-5" style={{ textAlign: "-webkit-center" }}>
        <Heading className="headingLoc"><Content heading={data.ourOfficesBlock.mainHeading1} /> </Heading>
      </div>
      <OfficeListing />


    </Layout>
  )
}
export default Offices
