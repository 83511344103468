import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import Office from './Office'
import './imagelayer.css'

const OfficeListing = () => {

  const data = useStaticQuery(graphql`
  query {
      allContentfulOffices(sort: {fields: contentfulid}) {
        edges {
          node {
            contentfulid
            cityName
            address
            telephone
            fax
            email
            image {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className="row">
      {
        data.allContentfulOffices.edges.map((edge) => {
          return (
            <Office
              key={edge.node.contentfulid}
              image={edge.node.image.fluid.src}
              city={edge.node.cityName}
              address={edge.node.address}
              phone={edge.node.telephone}
              fax={edge.node.fax}
              email={edge.node.email}
            />
          )
        })
      }
    </div>

  )
}

export default OfficeListing
